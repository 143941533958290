export enum ADMIN_ROUTES {
  ESHOPS = 'routesEshops',
  USERS = 'routesUsers',
  INVOICES = 'routesInvoices',
  NOT_FOUND = 'routesNotFound',
  ESHOP_DETAIL = 'routesEshopDetail',
  CREDIT_NOTES = 'routesCreditNotes',
  KPI_REPORTS = 'routesKpiReports',
  USER_DETAIL = 'routesUserDetail',
  LOGOUT_PAGE = 'routesLogoutPage',
  REGISTER_PAGE = 'routesRegisterPage',
  ACCOUNTING_PAGE = 'routesAccountingPage',
  EXPORT_PAGE = 'routesExportPage',
  FEED_PAGE = 'routesFeedPage',
  UNCREATED_CAMPAIGNS = 'routesUncreatedCampaigns',
  LOGS = 'routesLogs',
  APP_LOGS = 'routesAppLogs',
  EMAIL_LOGS = 'routesEmailLogs',
  ADMINS= 'routesAdmins',
  PRODUCTS = 'routesProducts',
  MICROSOFT_ACCOUNTS = 'routesMicrosoftAccounts',
}
